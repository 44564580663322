import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
} from "@material-ui/core"
import navigationStore from "../../../stores/navigation.store"
import {useHistory} from 'react-router-dom';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';


const OtherMenu = () => {
  const draweMenu = navigationStore(state => state.drawerMenu)
  
  let history = useHistory()
  
  const openMenu = (url) => {
    history.push(url);
  }
  
  return (
    <Grid container spacing={2}>
      {
        draweMenu.filter(menu => menu.visible === true).map((menu, index) => (
          <Grid key={index} item container md={6} direction="row">
            {
              <Grid item xs={12}>
                <Paper variant="outlined">
                  <List subheader={<ListSubheader>{menu.header}</ListSubheader>}>
                    {
                      menu.menus.filter(submenu => submenu.visible === true).map((submenu, submenuIndex) => (
                        <ListItem key={submenuIndex} button dense onClick={() => openMenu(submenu.url)}>
                          <ListItemText id={submenu.label} primary={submenu.label} />
                          <ListItemSecondaryAction>
                            <IconButton size="medium" onClick={() => openMenu(submenu.url)}>
                              <LaunchOutlinedIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                    }
                  </List>
                </Paper>
              </Grid>
            }
          </Grid>
        ))
      }
    </Grid>
  )
}

export default OtherMenu