import http, {dataRouteGroup} from "../../interfaces/http/http.interface";

const infoGroup = 'info';

const InfoDataService = {
  async fetchInfo() {
    const response = await http.get(dataRouteGroup + infoGroup);
    return await response.data;
  },
  async fetchInfoDetail({ infoId }) {
    const response = await http.post(dataRouteGroup + infoGroup + '/info-detail', {id: infoId});
    return await response.data;
  }
};

export default InfoDataService;