import { Box, Button, Divider, Toolbar, Typography } from "@material-ui/core"
import tagihanStyles from "../../../themes/routes/keuangan/tagihan"
import RefreshIcon from '@material-ui/icons/RefreshRounded'
import AgGrid from "../../ag-grid/AgGrid"
import useKeuanganStore from "../../../stores/keuangan.store";
import shallow from "zustand/shallow";
import useAuthStore from "../../../stores/auth.store";
import PrintIcon from "@material-ui/icons/Print";
import { baseURL } from "../../../interfaces/http/http.interface";

const Riwayat = () => {
  const user = useAuthStore( state => state.user )
  
  const classes = tagihanStyles()
  const {riwayat, loadRiwayat, isLoading} = useKeuanganStore(state => ({
    riwayat: state.riwayat,
    loadRiwayat: state.loadRiwayat,
    isLoading: state.isLoading
  }), shallow)
  const {data} = useAuthStore(state => state.user)
  
  const colDef = [
    {
      headerName: "No.",
      valueGetter: "node.rowIndex + 1"
    },
    {
      field: "Nama Mahasiswa",
    },
    {
      field: 'Tanggal Bayar'
    },
    {
      field: 'Bank Pembayaran'
    },
    {
      field: 'Nominal',
      type: 'numeric'
    },
    {
      field: 'Tahun Ajaran',
    }
  ]
  
  return (
    <>
      <Box className={classes.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Data Riwayat</Typography>
        <Typography variant="body2" gutterBottom align="center">Riwayat pembayaran mahasiswa UIR Pascasarjana</Typography>
      </Box>
      
      <Toolbar disableGutters>
        <div style={{flexGrow: 1}}/>
        <Button variant="text" color="primary" endIcon={<PrintIcon />}
                href={`${baseURL}cetak/riwayat-pembayaran/${btoa(user.data.npm)}`} disabled={isLoading}
                target="_blank"
        >
          Cetak Detail
        </Button>
        <Divider orientation="vertical" style={{height: 28}}/>
        <Button variant="text" color="primary" endIcon={<RefreshIcon/>}
                onClick={() => loadRiwayat(`${data.npm}`)} disabled={isLoading}>Segarkan</Button>
      </Toolbar>
  
      <AgGrid
        colDef={colDef}
        rowData={riwayat.data ?? null}
        fetchData={() => {
          if (riwayat.row === 0) {
            loadRiwayat(`${data.npm}`)
          }
        }}
        menu="Riwayat"
      />
      {/*<Card variant="outlined" className={classes.cardRoot}>*/}
      {/*  <CardHeader*/}
      {/*    title={*/}
      {/*      <Typography variant="subtitle2">Riwayat pembayaran mahasiswa UIR Pascasarjana</Typography>*/}
      {/*    }*/}
      {/*  />*/}
      {/*  <Divider/>*/}
      {/*  <CardContent className={classes.cardContent}>*/}
      {/*  </CardContent>*/}
      {/*</Card>*/}
    </>
  )
}

export default Riwayat