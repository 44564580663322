import {makeStyles} from "@material-ui/core/styles"

const profileStyles = makeStyles((theme) => ({
  pageTitle: {
    marginBottom: theme.spacing(6)
  },
  dataDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  profileImage: {
    display: 'block',
    width: theme.spacing(22),
    height: 'auto',
    // backgroundColor: 'transparent'
  },
  tabsDivider: {
    marginBottom: theme.spacing(4)
  }
}))

export default profileStyles