import {makeStyles} from "@material-ui/core/styles";

const dashboardTemplateStyles = drawerWidth => makeStyles((theme) => ({
  contentRoot: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing((8*2)),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.only('xs')]: {
      paddingBottom: theme.spacing((16*2))
    },
    // [theme.breakpoints.only('ss')]: {
    //   paddingBottom: theme.spacing((18*2))
    // },
    // minHeight: '100vh'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    }
  },
  mainContentContainer: {
    // minHeight: 'calc(100vh - 250px)'
  },
  footerDivider: {
    marginBottom: theme.spacing(1)
  },
  footerRoot: {
    position: 'absolute',
    bottom: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  footerShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    }
  }
}))

export default dashboardTemplateStyles