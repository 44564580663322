import http, {dataRouteGroup} from "../../interfaces/http/http.interface";

const inboxGroup = 'inbox';

const InboxDataService = {
  async fetchInbox() {
    const response = await http.get(dataRouteGroup + inboxGroup);
    return await response.data;
  },
  async fetchTotalUnreadInbox() {
    const response = await http.get(dataRouteGroup + inboxGroup + '/unread-message');
    return await response.data;
  },
  async fetchMessage({ messageId }) {
    const response = await http.post(dataRouteGroup + inboxGroup + '/read-message', {id: messageId});
    return await response.data;
  }
};

export default InboxDataService;