import {ThemeProvider} from '@material-ui/core/styles'
import muiTheme from "./themes/mui/mui-theme"
import {BrowserRouter as Router, Switch,} from 'react-router-dom'
import DashboardTemplate from "./components/routes/root/dashboard-template"
import ProtectedPage from "./interfaces/routes/protected-route"
import {SnackbarProvider} from 'notistack'
import {Button} from "@material-ui/core"
import React, {createRef} from "react"
import {QueryClient, QueryClientProvider} from "react-query";
import Login from "./components/routes/login/login";

const queryClient = new QueryClient();

function App() {
  const notistackRef = createRef()
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key)
  }
  
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={muiTheme}>
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => (
            <Button onClick={onClickDismiss(key)} variant="text" color="inherit">
              Tutup
            </Button>
          )}
        >
          <Router>
            <Switch>
              <ProtectedPage path="/login" exact>
                <Login />
              </ProtectedPage>
              <ProtectedPage path="/">
                <DashboardTemplate />
              </ProtectedPage>
            </Switch>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
