import {AppBar, Badge, IconButton, Toolbar, Tooltip, Typography, useMediaQuery} from '@material-ui/core';
import appbarStyles from "../../themes/common/appbar-common";
import navigationStore from '../../stores/navigation.store';
import MenuIcon from '@material-ui/icons/Menu';
import shallow from "zustand/shallow";
import clsx from "clsx";
import {useTheme} from "@material-ui/core/styles";
import useAuthStore from "../../stores/auth.store";
import {Link} from "react-router-dom";
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutline';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import {useQuery} from "react-query";
import InboxDataService from "../../services/data/inbox-data.service";
import useKeuanganStore from "../../stores/keuangan.store";
import useTAStore from "../../stores/ta.store";

const Appbar = ({drawerOpen, setDrawerOpen}) => {
  const {
    drawerWidth,
  } = navigationStore(state => ({
    drawerWidth: state.drawerWidth,
  }), shallow)
  const classes = appbarStyles(drawerWidth)()
  
  const logout = useAuthStore(state => state.logoutUser)
  const resetKeuangan = useKeuanganStore(state => state.resetKeuangan)
  const resetDataTa = useTAStore(state => state.resetDataTa)
  
  const doLogout = () => {
    logout();
    resetKeuangan();
    resetDataTa();
  }
  
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const {data} = useAuthStore(state => state.user)
  
  /** REACT QUERY */
  const {data: dataInbox} = useQuery(['inbox', 'totalInbox'], InboxDataService.fetchTotalUnreadInbox);
  
  return (
    <AppBar
      className={clsx(classes.appbarRoot, {
        [classes.appbarOpen]: drawerOpen,
      })}
      position="fixed"
      color="transparent"
      elevation={0}
    >
      <Toolbar>
        <Tooltip title="Menu" aria-label="">
          <IconButton edge="start" aria-label="menu" className={classes.hamburgerButton} onClick={setDrawerOpen}>
            <MenuIcon/>
          </IconButton>
        </Tooltip>
        <Link to="/" style={{textDecoration: 'none', color: 'initial'}}><Typography variant="subtitle1">{smUp ? 'Sistem Informasi Akademik' : 'SIKAD'}</Typography></Link>
        <div style={{flexGrow: 1}}/>
        <div className={classes.appbarMenuIconWrapper}>
          <Tooltip
            title={
              <>
                Profil<br/>
                <Typography gutterBottom={false} variant="caption">{data.npm}</Typography><br/>
                <Typography gutterBottom={false} variant="caption">{data.nama}</Typography>
              </>
            } aria-label="Profil">
            <IconButton component={Link} to="profile">
              <PersonOutlineTwoToneIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Kotak masuk" aria-label="Kotak masuk">
            <IconButton component={Link} to="pesan">
              <Badge badgeContent={dataInbox} color="secondary">
                <MailOutlineOutlinedIcon/>
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout" aria-label="logout">
            <IconButton edge="end" onClick={() => doLogout()}>
              <ExitToAppTwoToneIcon/>
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Appbar