import {
  Avatar,
  CardContent,
  Collapse,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import React, {useState} from "react";
import dompurify from 'dompurify';

const useStyle = makeStyles((theme) => ({
  bankLogo: {
    height: 'auto'
  },
  bankLogoWrapper: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(2)
  },
  logoContainer: {
    // width: theme.spacing(8)
  },
  bankTitle: {
    color: theme.palette.success.dark,
    fontFamily: 'Georgia',
    fontWeight: 'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogDetailPanduan = (props) => {
  const classes = useStyle();
  const {onClose, selectedValue, open, banks} = props;
  
  const [showDetail, setShowDetail] = useState('');
  
  const handleClose = () => {
    setShowDetail('');
    onClose(selectedValue);
  };
  
  const sanitizer = dompurify.sanitize;
  
  return (
    <Dialog onClose={handleClose} aria-labelledby="detail-panduan-pembayaran" open={open.open} maxWidth="md" fullWidth>
      <DialogTitle id="simple-dialog-title" style={{marginRight: '48px'}}>
        Metode Pembayaran Melalui {open.metode.title} <i><br/>{open.metode.subtitle}</i>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <List>
        {
          open.metode && (open.metode.data && open.metode.data.length > 0) ?
            open.metode.data.map((item) => (
              <React.Fragment key={item}>
                <ListItem button onClick={() => setShowDetail(item)}>
                  <ListItemAvatar className={classes.logoContainer}>
                    <Avatar
                      variant="square"
                      src={banks[item].icon}
                      className={classes.bankLogoWrapper}
                      classes={{
                        img: classes.bankLogo
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6" className={classes.bankTitle}>{banks[item].title}</Typography>
                    }
                    secondary="Lihat Instruksi Pembayaran"
                  />
                  {showDetail === item ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={showDetail === item} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography variant="body1"><b>Instruksi:</b></Typography>
                    <ol>
                      {
                        banks[item] && banks[item].panduan[open.metode.title] ?
                          banks[item].panduan[open.metode.title].map((poin) => (
                            <Typography variant="body1" key={poin}>
                              <li dangerouslySetInnerHTML={{__html: sanitizer(poin)}}/>
                            </Typography>
                          )) : null
                      }
                    </ol>
                  </CardContent>
                </Collapse>
              </React.Fragment>
            )) : null
        }
      </List>
    </Dialog>
  )
};

export default DialogDetailPanduan;