import {Controller} from "react-hook-form"
import {TextField} from "@material-ui/core"

const DefaultInput = ({ element, control, errors, disabled, variant }) => {
  return (
    <Controller
      name={element.name}
      control={control}
      defaultValue={element.defaultValue}
      render={({ field }) => <TextField
          {...field}
          type={element.type}
          label={element.label}
          fullWidth
          variant={variant || "outlined"}
          style={{
            backgroundColor: 'transparent'
          }}
          error={Boolean(errors[element.name])}
          helperText={errors[element.name]?.message}
          disabled={disabled}
          multiline={element.multiline ?? false}
          rowsMax={element.multiline ? 4:0}
        />
      }
    />
  )
}

export default DefaultInput