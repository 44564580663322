import {makeStyles} from "@material-ui/core/styles"
import grey from "@material-ui/core/colors/grey"

const krsStyles = makeStyles((theme) => ({
  appbarRoot: {
    // borderRadius: theme.spacing(1/2)
  },
  gridToolbar: {
    marginTop: theme.spacing(3)
  },
  cardRoot: {
    borderRadius: theme.spacing(1)
  },
  cardContent: {
  },
  pageTitle: {
    marginBottom: theme.spacing(6)
  },
  nilaiAktif: {
    backgroundColor: `${grey[200]} !important`
  }
}))

export default krsStyles