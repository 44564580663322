import http from "../../interfaces/http/http.interface"

const routeGroup = 'auth/'

const AuthService = {
  fetchLoginForm() {
    return http.get(routeGroup+'login-form')
  },
  verifyUserAccount(post) {
    return http.post(routeGroup+'login', post)
  },
  logoutUserAccount() {
    return http.get(routeGroup+'logout')
  }
}

export default AuthService