import FilterListIcon from "@material-ui/icons/FilterList"
import {Button, Dialog, DialogTitle, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import {useEffect, useState} from "react"
import tahunAjaranStyles from "../../../../themes/routes/akademik/partials/tahun-ajaran"
import useTAStore from "../../../../stores/ta.store"
import useAuthStore from "../../../../stores/auth.store"
import shallow from "zustand/shallow"
import {Skeleton} from "@material-ui/lab"

const TahunAjaran = ({ isLoading, initTa, setTa }) => {
  const classes = tahunAjaranStyles()
  
  const [open, setOpen] = useState(false)
  
  const { loadTA, dataTa, isLoadingTA } = useTAStore(state => ({ loadTA: state.loadTA, dataTa: state.dataTa, isLoadingTA: state.isLoadingTA}), shallow)
  const user = useAuthStore(state => state.user)
  
  useEffect(() => {
    if (dataTa.row <= 0 && user.row > 0)
    {
      loadTA(user.data.npm)
    }
  }, [dataTa, loadTA, user.data.npm, user.row])
  
  const handleChangeTA = (param) => {
    setTa(param)
    setOpen(!open)
  }
  
  return (
    <>
      <Button
        variant="text"
        startIcon={<FilterListIcon />}
        color="primary"
        onClick={() => setOpen(!open)}
        disabled={isLoading}
      >
        {
          dataTa.row > 0 && !isLoadingTA && !isLoading ?
            initTa ? dataTa.ta[initTa] : dataTa.ta[initTa]
            :
            <Skeleton variant="text" width={80} />
        }
      </Button>
  
      <Dialog open={open} onClose={() => setOpen(!open)}>
        <DialogTitle>Pilih Tahun Ajaran / Semester</DialogTitle>
        <List aria-label="pilih semester">
          {
            Object.keys(dataTa.ta).map(ta => (
              <ListItem button key={ta} selected={ta===initTa} onClick={() => handleChangeTA(ta)}>
                <ListItemIcon className={classes.dialogArrowList}>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText primary={dataTa.ta[ta]} />
              </ListItem>
            ))
          }
        </List>
      </Dialog>
    </>
  )
}

export default TahunAjaran