import shallow from "zustand/shallow";
import {useMutation} from "react-query";
import {useEffect} from "react";
import InfoDataService from "../../../services/data/info-data.service";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@material-ui/core";
import dompurify from "dompurify";
import useInfoStore from "../../../stores/info-store";

export default function InfoDetail() {
  /** COMMON */
  const sanitizer = dompurify.sanitize;
  
  /** ZUSTAND PROPS */
  const {
    infoId,
    setInfoId
  } = useInfoStore((state) => ({
    infoId: state.infoId,
    setInfoId: state.setInfoId,
  }), shallow);
  
  /** REACT QUERY */
  const {mutateAsync, data, isLoading} = useMutation(InfoDataService.fetchInfoDetail);
  
  /** PROPS */
  const infoIdIsNotEmpty = infoId !== '';
  
  /** USE EFFECT */
  useEffect(() => {
    (async () => {
      if (infoId !== '') {
        await mutateAsync({ infoId })
      }
    })()
  }, [infoId, mutateAsync])
  
  // return null if infoId is empty
  if (!infoId) return null;
  
  return (
    <Dialog onClose={() => setInfoId('')} open={infoIdIsNotEmpty} maxWidth="md">
      <DialogTitle>{isLoading ? 'Harap Tunggu...' : data ? data?.judul : 'Info tidak ditemukan'}</DialogTitle>
      <DialogContent>
        {
          isLoading ?
            <Grid container spacing={0} alignItems="center">
              <Grid item>
                <CircularProgress/>
              </Grid>
              <Grid item>
                <Typography style={{marginLeft: '16px'}}>Sedang mengambil info ...</Typography>
              </Grid>
            </Grid>
            :
            data ?
              <div style={{fontFamily: 'Segoe UI'}} dangerouslySetInnerHTML={{__html: sanitizer(data.informasi)}} />
              :
              <Typography variant="body2">Info tidak ditemukan</Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setInfoId('')}>
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  )
}