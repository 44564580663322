import {Button} from "@material-ui/core";
import usePesanStore from "../../../stores/pesan.store";

const ButtonReadCellRenderer = (props) => {
  /** ZUSTAND PROPS */
  const setMessageId = usePesanStore(state => state.setMessageId);
  
  /** AG GRID PROPS */
  const { data } = props;
  
  const openMessage = () => {
    const {Action} = data;
    setMessageId(Action)
  }
  
  return (
    <Button variant="outlined" color="primary" size="small" onClick={openMessage}>Buka Pesan</Button>
  )
};

export default ButtonReadCellRenderer;