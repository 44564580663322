import create from "zustand";

const infoStore = (set, get) => ({
  infoId: '',
  setInfoId: (newInfoId) => set({ infoId: newInfoId }),
});

const useInfoStore = create(infoStore);

export default useInfoStore;
