import {Grid, TextField} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

const GridInformasi = ({ data, isLoading }) => {
  return (
    <Grid container spacing={2} justifyContent="space-evenly">
      {
        data && !isLoading ?
          Object.keys(data).map((key, index) =>
            <Grid container item xs={12} sm={(index+1) === Object.keys(data).length && (index+1) % 2 !== 0 ? 12:6} key={key}>
              <TextField label={key} value={data[key]} readOnly fullWidth color={"secondary"} variant="filled" size="small" />
            </Grid>
          )
          :
          <>
            <Grid container item xs={12} sm={6} lg={6}>
              <Skeleton width={'100%'} height={84} />
            </Grid>
            <Grid container item xs={12} sm={6} lg={6}>
              <Skeleton width={'100%'} height={84} />
            </Grid>
          </>
      }
    </Grid>
  )
}

export default GridInformasi