import create from "zustand"
import KeuanganService from "../services/data/keuangan-data.service";

const initialState = {
  tagihan: {
    data: null,
    row: 0
  },
  riwayat: {
    data: null,
    row: 0
  },
};

const handleLoadTagihan = async (set, get, param) => {
  set({ isLoading: true })
  
  try {
    const response = await KeuanganService.fetchKeuangan('tagihan', param)
    const data = await response.data
    
    set({ isLoading: false, tagihan: data })
  } catch (e) {
    console.log(e)
  }
}

const handleLoadRiwayat = async (set, get, param) => {
  set({ isLoading: true })
  
  try {
    const response = await KeuanganService.fetchKeuangan('riwayat-tagihan', param)
    const data = await response.data
    
    set({ isLoading: false, riwayat: data })
  } catch (e) {
    console.log(e)
  }
}

const keuanganStore = (set, get) => ({
  isLoading: false,
  tagihan: initialState.tagihan,
  riwayat: initialState.riwayat,
  loadTagihan: async (param) => { await handleLoadTagihan(set, get, param)},
  loadRiwayat: async (param) => { await handleLoadRiwayat(set, get, param)},
  resetKeuangan: () => set({ tagihan: initialState.tagihan, riwayat: initialState.riwayat })
})

const useKeuanganStore = create(keuanganStore)

export default useKeuanganStore