import * as yup from "yup"

const generateYupSchema = (schema, config) => {
  const { name, validationType, validations = [] } = config

  if (!yup[validationType]) {
    return schema
  }

  let validator = yup[validationType]()
  validations.forEach(validation => {
    const { params, type } = validation
    if (!validator[type]) {
      return
    }
    
    if (type === 'when') {
      const [ chain, is, errMsg ] = params
      // const [ chain, condition, is, errMsg ] = params

      // validator = validator[type](chain, eval(condition))
      validator = validator[type](chain, (chain, schema) => {
        return chain === is ? schema.required(errMsg) : schema })
    } else if (type === 'oneOf') {
      const [ referenceField, errMsg ] = params;
      validator = validator[type]([yup.ref(referenceField)], errMsg);
    } else {
      // console.log(typeof(type), params)
      validator = validator[type](...params)
      // console.log(JSON.stringify(validator))
    }
  })

  // console.log(validator)
  schema[name] = validator
  return schema
}

export default generateYupSchema