import {Button, Link} from "@material-ui/core"
import PrintIcon from "@material-ui/icons/Print"
import {Skeleton} from "@material-ui/lab"

const Cetak = ({ link, isLoadingKRS }) => {
  return (
    <Button
      variant="text"
      endIcon={<PrintIcon />}
      color="primary"
      disabled={isLoadingKRS}
      component={Link}
      href={link}
      target="_blank"
      rel="noopener"
    >
      {!isLoadingKRS ? 'cetak':<Skeleton variant="text" width={80} />}
    </Button>
  )
}

export default Cetak