import create from "zustand"
import KrsService from "../services/data/krs-data.service"

const initialState = {
  dataTa: {
    row: 0,
    ta: {
      id_tahun_ajaran: null,
      tahun_ajaran: null
    }
  }
};

const handleLoadTA = async (set, get, param) => {
  set({ isLoadingTA: true })
  
  try {
    const response = await KrsService.fetchTA(param)
    const data = await response.data
    
    set({ dataTa: data })
    set({ isLoadingTA: false })
  } catch (e) {
    console.log(e)
  }
}

const taStore = (set, get) => ({
  isLoadingTA: false,
  taAktif: '',
  dataTa: initialState.dataTa,
  loadTA: async (param) => { await handleLoadTA(set, get, param)},
  setTaAktif: (param) => set({ taAktif: param }),
  resetDataTa: () => set({ dataTa: initialState.dataTa })
})

const useTAStore = create(taStore)

export default useTAStore