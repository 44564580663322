import {Box, Button, Grid, Link, Typography} from "@material-ui/core";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import {baseURL} from "../../../interfaces/http/http.interface";
import krsStyles from "../../../themes/routes/akademik/krs";
import useAuthStore from "../../../stores/auth.store";

const Ktm = () => {
  const classes = krsStyles()
  const user = useAuthStore( state => state.user )
  
  return (
    <>
      <Box className={classes.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Kartu Tanda Mahasiswa (KTM)</Typography>
        <Typography variant="body2" gutterBottom align="center">Download / cetak KTM Digital.</Typography>
      </Box>
    
      <Grid container justifyContent="center" direction="column" alignContent="center" alignItems="center" spacing={1}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppRoundedIcon />}
            component={Link}
            href={`${baseURL}cetak/ktm-digital/${user.data.npm}`}
            target="_blank"
            rel="noopener"
          >Download KTM
          </Button>
        </Grid>
        <Grid item>
          <Typography align="center" variant="subtitle2">Klik tombol diatas untuk download.</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Ktm;