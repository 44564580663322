import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import {Box, Card, CardContent, Divider, Toolbar, Typography,} from '@material-ui/core'
import krsStyles from "../../../themes/routes/akademik/krs"
import {useEffect, useState} from "react"
import useAuthStore from "../../../stores/auth.store"
import TahunAjaran from "./partials/tahun-ajaran"
import Cetak from "./partials/cetak"
import {baseURL} from "../../../interfaces/http/http.interface"
import AgGrid from "../../ag-grid/AgGrid"
import GridInformasi from "./partials/grid-informasi"
import MuiAlert from '@material-ui/lab/Alert'
import KrsService from "../../../services/data/krs-data.service";
import ContentDividerCommon from "../../common/content-divider.common";

const Khs = () => {
  const classes = krsStyles()
  
  const user = useAuthStore(state => state.user)
  
  const [isLoading, setIsLoading] = useState(false);
  const [isianKhs, setIsianKhs] = useState({
    mahasiswa: {},
    khs: {
      data: null,
      info: {
        jumlah_sks: 0,
        total_sks: 0,
        ip: '0.00'
      },
      nilai_aktif: 0,
    },
    tahun_ajaran: null,
    row: 0
  })
  
  const handleLoadKhs = async (param) => {
    setIsLoading(true)
    
    try {
      const response = await KrsService.fetchKrsKhs('khs', param)
      const data = await response.data
      
      setIsLoading(false);
      setIsianKhs(data);
    } catch (e) {
      console.log(e)
    }
  }
  
  useEffect(() => {
    return isianKhs.tahun_ajaran && isianKhs.row === 0 && user.data.npm && handleLoadKhs(`${user.data.npm}/${isianKhs.tahun_ajaran}`);
  }, [isianKhs, user])
  
  const colDef = [
    {
      field: 'NO'
    },
    {
      field: 'KODE MK',
    },
    {
      field: "MATA KULIAH",
    },
    {
      field: 'NILAI',
    },
    {
      field: 'BOBOT',
    },
    {
      field: "SKS",
    },
    {
      field: "MUTU",
    },
  ]
  
  return (
    <>
      <Box className={classes.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Kartu Hasil Studi (KHS)</Typography>
        <Typography variant="body2" gutterBottom align="center">Kartu Hasil Studi Mahasiswa UIR Pascasarjana</Typography>
      </Box>
      
      <Toolbar disableGutters>
        <div style={{flexGrow: 1}}/>
        <TahunAjaran
          isLoading={isLoading}
          initTa={isianKhs.tahun_ajaran}
          setTa={(param) =>
            setIsianKhs(prevState => {
              return {
                ...prevState,
                tahun_ajaran: param,
                row: 0
              }
            })}
        />
        <Divider orientation="vertical" style={{height: 28}}/>
        <Cetak isLoadingKRS={isLoading}
               link={`${baseURL}cetak/khs-mahasiswa/${user.data.npm}/${isianKhs.tahun_ajaran}`}/>
      </Toolbar>
      
      <Card variant="outlined" className={classes.cardRoot}>
        {/*<CardHeader*/}
        {/*  title={*/}
        {/*    <Typography variant="subtitle2">Kartu Hasil Studi Mahasiswa UIR Pascasarjana</Typography>*/}
        {/*  }*/}
        {/*/>*/}
        {/*<Divider/>*/}
        <CardContent className={classes.cardContent}>
          <GridInformasi data={isianKhs.mahasiswa} isLoading={isLoading}/>
          <ContentDividerCommon />
          <GridInformasi data={isianKhs.khs.info} isLoading={isLoading}/>
          <ContentDividerCommon />
          <AgGrid
            colDef={colDef}
            rowData={isianKhs.khs.data ?? null}
            fetchData={() => isianKhs.row === 0 && handleLoadKhs(`${user.data.npm}`)}
            menu="KHS"
            rowClassRules={{
              [classes.nilaiAktif]: param => {
                return param.data.flag_aktif === '1' && (param.data.NILAI || param.data.NILAI === '-')
              }
            }}
          />
          {
            isianKhs.khs.nilai_aktif > 0 ?
              <MuiAlert variant="standard" severity="info" style={{marginTop: '16px'}}>
                <Typography variant="caption">
                  Nilai dalam kolom berwarna <b><u>abu-abu</u></b> belum dimasukkan dalam penghitungan Indeks Prestasi
                  (IP) dan Indeks Prestasi Kumulatif (IPK) karena nilai belum diverifikasi oleh Prodi dan nilai masih
                  dapat berubah jika terdapat kesalahan.
                </Typography>
              </MuiAlert>
              :
              null
          }
        </CardContent>
      </Card>
    </>
  )
}

export default Khs