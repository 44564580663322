import create from "zustand";

const pesanStore = (set, get) => ({
  open: false,
  messageId: '',
  setOpen: (newState) => set({ open: newState }),
  setMessageId: (newMessageId) => set({ messageId: newMessageId }),
});

const usePesanStore = create(pesanStore);

export default usePesanStore;
