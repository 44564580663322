import {makeStyles} from '@material-ui/core/styles'
import lightPPs from '../../../resources/images/lightpps.jpeg'

const loginStyles = makeStyles((theme) => ({
  bodyRoot: {
    minHeight: '100vh',
    backgroundColor: "inherit",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: 'center 0',
    backgroundImage: `url(${lightPPs})`,
    backgroundAttachment: "fixed",
    '&::before': {
      width: '100%',
      height: theme.spacing(4),
      content: '""',
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(1),
      }
    },
    '&::after': {
      width: '100%',
      height: theme.spacing(4),
      content: '""',
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        height: 0,
      }
    }
  },
  cardRoot: {
    borderRadius: theme.spacing(1),
    // borderColor: '#030',
    backgroundColor: 'rgba(255, 255, 255, .8)',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 0),
      borderRadius: 0,
      border: 0,
    },
    backdropFilter: "blur(3px)"
  },
  cardHeader: {
    // marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  cardContent: {
    maxWidth: theme.spacing(40),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    margin: theme.spacing(0, 'auto', 2)
  },
  cardHeaderAvatar: {
    // marginRight: theme.spacing(1)
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1)
    }
  },
  mainGrid: {
    // [theme.breakpoints]
    // flexDirection: 'column',
  },
  uirLogo: {
    maxWidth: theme.spacing(5),
    marginTop: theme.spacing(.2)
  },
  logoTitle: {
    color: '#030',
    fontFamily: 'Georgia Regular',
    fontWeight: 'bold',
    fontSize: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      lineHeight: '1.2'
    }
  },
  logoSubtitle: {
    color: '#030',
    fontFamily: 'Georgia Regular',
    fontWeight: '500',
    marginTop: theme.spacing(-1.2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(-.4),
      textAlign: 'center',
    }
  },
  btnLogin: {
    background: `linear-gradient(to top, rgba(50, 11, 69, 1), ${theme.palette.primary.main})`,
  },
  footer: {
    // backgroundColor: 'rgba(255, 255, 255, .8)',
    backgroundColor: 'rgba(0, 0, 0, .2)',
    backdropFilter: "blur(4px)"
  },
  headerDivider: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      marginTop: 0
    }
  },
  formDivider: {
    [theme.breakpoints.only("sm")]: {
      marginBottom: theme.spacing(2)
    }
  },
}))

export default loginStyles