import React, {useMemo} from 'react';
import {Box, Divider, Typography} from "@material-ui/core";
import commonStyles from "../../../themes/common/common";
import InfoDetail from "./info-detail";
import OtherMenu from "./other-menu";
import Informasi from "../../info/informasi";

export default function Info() {
  const commonClasses = commonStyles()
  
  const informasi = useMemo(() => {
    return <Informasi />
  }, [])
  
  return (
    <>
      <Box className={commonClasses.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Dashboard</Typography>
        <Typography variant="body2" gutterBottom align="center">Selamat datang di dashboard SIKAD UIR PPS
          👋</Typography>
      </Box>
      {informasi}
      
      <Divider style={{marginBottom: '16px', marginTop: '16px'}} light />
      
      <OtherMenu/>
      
      <InfoDetail />
    </>
  );
}