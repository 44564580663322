import useAuthStore from "../../stores/auth.store"
import {Redirect, Route, useHistory,} from 'react-router-dom'
import {useEffect} from "react"
import {LinearProgress} from '@material-ui/core'
import shallow from "zustand/shallow"

const ProtectedPage = ({children, path, location, ...rest}) => {
  const {user, loginForm, preAuth} = useAuthStore(state => ({
    user: state.user,
    loginForm: state.loginForm,
    preAuth: state.preAuth
  }), shallow)
  const history = useHistory()
  const {pathname} = location
  
  useEffect(() => {
    if (user.row === 0 && !loginForm.form) {
      preAuth()
    }
    
    if (user.row > 0 && pathname === '/login') {
      history.replace('/')
    }
  }, [user, history, loginForm.form, pathname, preAuth])
  
  return (
    loginForm.form || user.row > 0 ?
      <Route
        {...rest}
        render={({location}) =>
          // user sudah login
          user.row > 0 ? (
            // route tidak login
            pathname !== '/login' ?
              // tampilkan yg diminta
              children
              :
              // redirect ke login
              <Redirect
                to={{
                  pathname: pathname,
                  state: {from: location}
                }}
              />
          ) : (
            pathname !== '/login' ?
              <Redirect
                to={{
                  pathname: "/login",
                  state: {from: location}
                }}
              />
              :
              children
          )
        }
      />
      :
      <LinearProgress/>
  )
}

export default ProtectedPage