import {createTheme} from '@material-ui/core/styles'
import {purple} from '@material-ui/core/colors'

const muiTheme = createTheme({
  palette: {
    primary: {
      main: purple[700]
    }
  },
  typography: {
    fontFamily: [
      'Segoe UI',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      // ss: 360,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    }
  }
})

export default muiTheme