import {useEffect, useState} from "react";
import generateYupSchema from "../../../../helpers/generate-yup_schema";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {CircularProgress, Grid} from "@material-ui/core";
import DynamicForms from "../../../forms/dynamic.forms";

const FormProfile = ({ formElements, onSubmit, isLoadingForm }) => {
  const [schema, setSchema] = useState({});
  
  useEffect(() => {
    if (formElements.length > 0) setSchema(formElements.reduce(generateYupSchema, {}));
  }, [formElements])
  
  const {
    handleSubmit,
    control,
    formState: {errors, isSubmitting}
  } = useForm({
    shouldFocusError: true,
    resolver: yupResolver(yup.object().shape(schema)),
  });
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {
          !isLoadingForm && formElements && formElements.length > 0 ?
            formElements.map((element) => (
              <Grid item xs={12} key={element['__uid']}>
                <div key={element.__uid}>
                  <DynamicForms variant="filled" elements={element} control={control} fullwidth={false}
                                errors={errors} disabled={isSubmitting} />
                </div>
              </Grid>
            ))
            : <CircularProgress />
        }
      </Grid>
    </form>
  )
};

export default FormProfile;