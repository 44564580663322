import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography
} from "@material-ui/core";
import panduanStyles from "../../../themes/routes/keuangan/panduan";
import {useState} from "react";
import DialogDetailPanduan from "./dialog-detail-panduan";
import {useQuery} from "react-query";
import KeuanganService from "../../../services/data/keuangan-data.service";

const Panduan = () => {
  const classes = panduanStyles()
  
  const {isLoading, data: {method, banks}} = useQuery('panduanPembayaran', KeuanganService.fetchPanduanPembayaran, {
    initialData: {
      method: {},
      banks: {}
    },
    refetchOnWindowFocus: false
  });
  
  const [open, setOpen] = useState({
    open: false,
    metode: {}
  })
  
  const handleClose = () => {
    setOpen({
      ...open,
      open: false
    })
  }
  
  return (
    <>
      <Box className={classes.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Panduan Pembayaran</Typography>
        <Typography variant="body2" gutterBottom align="center">Tatacara/panduan pembayaran tagihan kuliah.</Typography>
      </Box>
      
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {
          isLoading
            ? <Grid item>
              <CircularProgress/>
            </Grid>
            : typeof method === 'object' && Object.keys(method).length
              ? Object.keys(method).map((item, index) =>
                <Grid item xs={8} md={5} xl={3} key={index}>
                  <Card style={{height: '100%'}} variant="outlined">
                    <CardActionArea className={classes.cardRoot}
                                    onClick={() => setOpen({open: true, metode: method[item]})}>
                      <CardHeader
                        title={<Typography align="center" variant="h5">
                          {method[item].title}
                        </Typography>}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <img
                          src={method[item].icon}
                          alt="Teller"
                          className={classes.icon}
                        />
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>)
              : <Typography>Data tidak tersedia, coba lagi!</Typography>
        }
      </Grid>
      <DialogDetailPanduan open={open} onClose={handleClose} banks={banks} />
    </>
  )
};

export default Panduan;