import {AgGridReact} from "ag-grid-react";
import {Paper} from "@material-ui/core";
import React from "react";
import NoRowsAvailable from "./overlays/no-rows.overlay";
import StatusCellRenderer from "../routes/pesan/StatusCellRenderer";

const AgGrid = ({colDef, rowData, fetchData, menu, rowClassRules = {}}) => {
  return (
    <Paper
      variant="outlined"
      className="ag-theme-material"
      // style={{ marginTop: 18}}
      square
    >
      <AgGridReact
        domLayout='autoHeight'
        rowData={rowData}
        defaultColDef={{
          resizable: true,
        }}
        columnDefs={colDef}
        onGridReady={fetchData}
        onRowDataChanged={params =>{
          const gridColumnApi = params.columnApi
    
          let allColumnIds = []
          gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId)
          })
          gridColumnApi.autoSizeColumns(allColumnIds, false)
        }}
        frameworkComponents={{
          noRowsAvailable: NoRowsAvailable,
          statusCellRenderer: StatusCellRenderer
        }}
        noRowsOverlayComponent='noRowsAvailable'
        noRowsOverlayComponentParams={{
          menu: menu
        }}
        rowClassRules={rowClassRules}
      />
    </Paper>
  )
}

export default AgGrid