import drawerStyles from "../../themes/common/drawer-common"
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography,
} from '@material-ui/core'
import navigationStore from "../../stores/navigation.store"
import uirLogo from "../../resources/images/uir-new-small.png"
import shallow from 'zustand/shallow'
import {useEffect} from "react"
import {Link as RouterLink, useLocation} from 'react-router-dom'

const DrawerApp = ({drawerOpen, setDrawerOpen, mdUp}) => {
  const {
    drawerWidth,
    drawerMenu
  } = navigationStore(state => ({
    drawerWidth: state.drawerWidth,
    drawerMenu: state.drawerMenu
  }), shallow)
  const classes = drawerStyles(drawerWidth)()
  
  useEffect(() => {
    localStorage.setItem('drw', drawerOpen)
  }, [drawerOpen]);
  
  const {pathname} = useLocation();
  
  return (
    <Drawer
      className={classes.drawerRoot}
      variant={mdUp ? 'persistent' : 'temporary'}
      anchor="left"
      open={drawerOpen}
      onClose={setDrawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar className={classes.drawerHeader}>
        <img src={uirLogo} alt="Logo UIR" className={classes.uirLogo}/>
        <Box
          display="flex"
          flexDirection="column"
        >
          <Typography className={classes.drawerTitle}>UNIVERSITAS ISLAM RIAU</Typography>
          <Typography className={classes.drawerSubtitle} variant="caption">Program Pascasarjana</Typography>
        </Box>
      </Toolbar>
      <Divider/>
      <List dense>
        {
          drawerMenu.map((menu, index) => (
            <li key={index}>
              <ul style={{padding: 0}}>
                <ListSubheader disableSticky key={menu.index} color="inherit">{menu.header}</ListSubheader>
                {menu.menus.map(item => (
                  <ListItem
                    button
                    className={classes.drawerMenuItem}
                    key={item.url}
                    component={RouterLink}
                    to={item.url}
                    selected={pathname === item.url}
                    onClick={() => {
                      if (!mdUp) setDrawerOpen()
                    }}
                  >
                    <ListItemText primary={item.label}/>
                  </ListItem>
                ))}
              </ul>
            </li>
          ))
        }
      </List>
    </Drawer>
  )
}

export default DrawerApp