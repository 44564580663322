import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import {Box, Card, CardContent, Divider, Toolbar, Typography,} from '@material-ui/core'
import krsStyles from "../../../themes/routes/akademik/krs"
import {useEffect, useState} from "react"
import useAuthStore from "../../../stores/auth.store"
import TahunAjaran from "./partials/tahun-ajaran"
import Cetak from "./partials/cetak"
import {baseURL} from "../../../interfaces/http/http.interface"
import AgGrid from "../../ag-grid/AgGrid"
import GridInformasi from "./partials/grid-informasi"
import KrsService from "../../../services/data/krs-data.service";
import ContentDividerCommon from "../../common/content-divider.common";

const Krs = () => {
  const classes = krsStyles()
  
  const user = useAuthStore(state => state.user)
  
  const [isLoading, setIsLoading] = useState(false);
  const [isianKrs, setIsianKrs] = useState({
    mahasiswa: {},
    krs: null,
    tahun_ajaran: null,
    cols: [],
    row: 0
  })
  
  const handleLoadKrs = async (param) => {
    setIsLoading(true);
    
    try {
      const response = await KrsService.fetchKrsKhs('krs', param)
      const data = await response.data
      
      setIsLoading(false);
      setIsianKrs(data)
    } catch (e) {
      console.error(e)
    }
  }
  
  useEffect(() => {
    return isianKrs.tahun_ajaran && isianKrs.row === 0 && user.data.npm && handleLoadKrs(`${user.data.npm}/${isianKrs.tahun_ajaran}`);
  }, [isianKrs, user])
  
  return (
    <>
      <Box className={classes.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Kartu Rencana Studi (KRS)</Typography>
        <Typography variant="body2" gutterBottom align="center">Isian KRS Mahasiswa UIR Pascasarjana</Typography>
      </Box>
      
      <Toolbar disableGutters>
        <div style={{flexGrow: 1}}/>
        <TahunAjaran
          isLoading={isLoading}
          initTa={isianKrs.tahun_ajaran}
          setTa={(param) => {
            setIsianKrs(prevState => {
              return {
                ...prevState,
                tahun_ajaran: param,
                row: 0
              }
            })
          }}
        />
        <Divider orientation="vertical" style={{height: 28}}/>
        <Cetak isLoadingKRS={isLoading}
               link={`${baseURL}cetak/krs-mahasiswa/${user.data.npm}/${isianKrs.tahun_ajaran}`}/>
      </Toolbar>
      
      <Card variant="outlined" className={classes.cardRoot}>
        <CardContent className={classes.cardContent}>
          <GridInformasi data={isianKrs.mahasiswa} isLoading={isLoading}/>
          <ContentDividerCommon />
          <AgGrid
            colDef={isianKrs.cols}
            rowData={!isLoading ? isianKrs.krs ?? null : null}
            fetchData={() => {
              if (isianKrs.row === 0) {
                handleLoadKrs(`${user.data.npm}`)
              }
            }}
            menu="KHS"
          />
        </CardContent>
      </Card>
    </>
  )
}

export default Krs