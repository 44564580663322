import UserService from "../services/data/user-data.service";
import create from "zustand";

const handleLoadProfile = async (set) => {
  set({ isLoading: true, profile: {} })
  
  try {
    const response = await UserService.fetchUserProfile()
    const data = await response.data
    
    set({ isLoading: false, profile: data })
  } catch (e) {
    console.log(e)
  }
}

const userStore = (set, get) => ({
  isLoading: false,
  profile: {},
  loadProfile: async (param) => { await handleLoadProfile(set)}
})

const useUserStore = create(userStore)

export default useUserStore