import {useQuery} from "react-query";
import KeuanganService from "../../../services/data/keuangan-data.service";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import useAuthStore from "../../../stores/auth.store";

const TagihanNotification = () => {
  const [open, setOpen] = useState(false);
  
  const closeDialog = () => {
    setOpen(false);
  }
  
  const {data} = useAuthStore(state => state.user)
  
  /** REACT ROUTER DOM */
  const history = useHistory();
  const goToTagihan = () => {
    history.push('/data-tagihan');
    setOpen(false);
  }
  
  /** REACT QUERY */
  const {data: totalTagihan} = useQuery(['totalTagihan', data.npm], KeuanganService.fetchTotalTagihan,
    {
      initialData: 0
    });
  
  useEffect(() => {
    setOpen(Boolean(totalTagihan));
  }, [totalTagihan])
  
  if (!totalTagihan) return null;
  
  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle>Pemberitahuan</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Anda memiliki {totalTagihan} tagihan yang belum dibayarkan.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>Tutup</Button>
        <Button color="primary" onClick={goToTagihan}>Lihat tagihan</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagihanNotification;