import {Chip} from "@material-ui/core";

const StatusCellRenderer = (props) => {
  const { value } = props;
  const label = value ? 'Sudah dibaca':'Belum dibaca';
  const color = value ? 'initial' : 'secondary';
  return (
    <Chip label={label} color={color} size="small"/>
  )
};

export default StatusCellRenderer;