import {makeStyles} from "@material-ui/core/styles"

const appbarStyles = drawerWidth => makeStyles((theme) => ({
  appbarRoot: {
    backgroundColor: 'white',
    borderBottom: '1px solid '+theme.palette.grey[300],
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginLeft: '0',
    },
  },
  appbarOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: '300px',
    },
  },
  appbarLogo: {
    maxWidth: theme.spacing(4)
  },
  hamburgerButton: {
    marginRight: theme.spacing(1)
  },
  buttonAvatar: {
    marginRight: theme.spacing(-2)
  },
  avatarContainer: {
    minWidth: 'auto',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2)
    }
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatarSecondaryText: {
    display: 'block'
  },
  menuItemProfile: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  appbarMenuIconWrapper: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  }
}))

export default appbarStyles