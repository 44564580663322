import http, {dataRouteGroup} from "../../interfaces/http/http.interface";

const KeuanganService = {
  fetchKeuangan(dokumen, param) {
    return http.get(dataRouteGroup + `${dokumen}-mahasiswa/` + param)
  },
  async fetchTotalTagihan() {
    const response = await http.get(dataRouteGroup + '/total-tagihan');
    return await response.data;
  },
  async fetchPanduanPembayaran() {
    const response = await http.get(dataRouteGroup + '/panduan-pembayaran');
    return await response.data;
  }
}

export default KeuanganService