import http, {dataRouteGroup} from "../../interfaces/http/http.interface";

const KrsService = {
  fetchKrsKhs(dokumen, param) {
    return http.get(dataRouteGroup+`${dokumen}-mahasiswa/`+param)
  },
  fetchTA(param) {
    return http.get(dataRouteGroup+'tahun-ajaran/'+param)
  }
}

export default KrsService