import { Box, Button, Divider, Toolbar, Typography } from "@material-ui/core"
import tagihanStyles from "../../../themes/routes/keuangan/tagihan"
import RefreshIcon from '@material-ui/icons/RefreshRounded'
import AgGrid from "../../ag-grid/AgGrid"
import useKeuanganStore from "../../../stores/keuangan.store";
import shallow from "zustand/shallow";
import useAuthStore from "../../../stores/auth.store";
import PrintIcon from "@material-ui/icons/Print";
import { baseURL } from "../../../interfaces/http/http.interface";

const Tagihan = () => {
  const user = useAuthStore( state => state.user )
  
  const classes = tagihanStyles()
  const {tagihan, loadTagihan, isLoading} = useKeuanganStore(state => ({
    tagihan: state.tagihan,
    loadTagihan: state.loadTagihan,
    isLoading: state.isLoading
  }), shallow)
  const {data} = useAuthStore(state => state.user)
  
  const colDef = [
    {
      headerName: "No.",
      valueGetter: "node.rowIndex + 1"
    },
    {
      field: 'Jenis Pembayaran'
    },
    {
      field: 'Tahun Ajaran'
    },
    {
      field: 'Bank Pembayaran'
    },
    {
      field: 'Nominal'
    }
  ]
  
  return (
    <>
      <Box className={classes.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Data Tagihan</Typography>
        <Typography variant="body2" gutterBottom align="center">Tagihan mahasiswa UIR Pascasarjana</Typography>
      </Box>
      
      <Toolbar disableGutters>
        <div style={{flexGrow: 1}}/>
        <Button variant="text" color="primary" endIcon={<PrintIcon />}
                href={`${baseURL}cetak/tagihan-pembayaran/${btoa(user.data.npm)}`} disabled={isLoading}
                target="_blank"
        >
          Cetak Detail
        </Button>
        <Divider orientation="vertical" style={{height: 28}}/>
        
        <Button variant="text" color="primary" endIcon={<RefreshIcon/>}
                onClick={() => loadTagihan(`${data.npm}`)} disabled={isLoading}>Segarkan</Button>
      </Toolbar>
  
      <AgGrid
        colDef={colDef}
        rowData={tagihan.data ?? null}
        fetchData={() => {
          if (tagihan.data === null) {
            loadTagihan(`${data.npm}`)
          }
        }}
        menu="Tagihan"
      />
      
      {/*<Card variant="outlined" className={classes.cardRoot}>*/}
      {/*  <CardHeader*/}
      {/*    title={*/}
      {/*      <Typography variant="subtitle2">Tagihan mahasiswa UIR Pascasarjana</Typography>*/}
      {/*    }*/}
      {/*  />*/}
      {/*  <Divider/>*/}
      {/*  <CardContent className={classes.cardContent}>*/}
      {/*    */}
      {/*  </CardContent>*/}
      {/*</Card>*/}
    </>
  )
}

export default Tagihan