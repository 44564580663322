import {makeStyles} from "@material-ui/core/styles";

const panduanStyles = makeStyles((theme) => ({
  pageTitle: {
    marginBottom: theme.spacing(6)
  },
  cardRoot: {
    height: '100%',
    display:  'flex',
    flexDirection: 'column'
  },
  cardHeader: {
    flexGrow: 1
  },
  btnPilih: {
    padding: theme.spacing(1, 0),
    borderRadius: '8px'
    // textTransform: "none",
  },
  icon: {
    width: '100%'
  },
}))

export default panduanStyles