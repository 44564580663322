import {Box, Button, ButtonGroup, Paper, Toolbar, Typography} from "@material-ui/core";
import React from "react";
import commonStyles from "../../../themes/common/common";
import {useQuery, useQueryClient} from "react-query";
import InboxDataService from "../../../services/data/inbox-data.service";
import StatusCellRenderer from "./StatusCellRenderer";
import {AgGridReact} from "ag-grid-react";
import NoRowsAvailable from "../../ag-grid/overlays/no-rows.overlay";
import {Refresh} from "@material-ui/icons";
import ButtonReadCellRenderer from "./ButtonReadCellRenderer";
import ViewPesan from "./view.pesan";

const Pesan = () => {
  /** COMMON PROPERTY */
  const commonClasses = commonStyles();
  
  /** AG GRID PROPERTY */
  const columnDefs = [
    {
      field: 'No',
      width: 80
    },
    {
      field: 'Judul'
    },
    {
      field: 'Tanggal Diterima',
      width: 220
    },
    {
      field: 'Status',
      cellRenderer: 'statusCellRenderer',
      width: 150
    },
    {
      field: 'Action',
      cellRenderer: 'buttonReadCellRenderer',
      width: 150,
    },
  ];
  
  /** REACT QUERY PROPERTY */
  const {
    data, isFetching
  } = useQuery('inbox', InboxDataService.fetchInbox);
  const queryClient = useQueryClient();
  
  return (
    <>
      <Box className={commonClasses.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Inbox</Typography>
        <Typography variant="body2" gutterBottom align="center">Pesan yang dikirimkan oleh Pihak Terkait terhadap
          Mahasiswa</Typography>
      </Box>
  
      <Toolbar variant="dense" style={{overflow: "auto"}}>
        <div style={{flexGrow: 1}}/>
        <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
          <Button size="small" startIcon={<Refresh/>} disabled={isFetching} onClick={async () => {
            await queryClient.invalidateQueries('inbox')
          }}>Segarkan</Button>
        </ButtonGroup>
      </Toolbar>
      <Paper
        variant="outlined"
        className={`ag-theme-material`}
        square
      >
        <AgGridReact
          domLayout='autoHeight'
          rowData={data}
          columnDefs={columnDefs}
          frameworkComponents={{
            noRowsAvailable: NoRowsAvailable,
            statusCellRenderer: StatusCellRenderer,
            buttonReadCellRenderer: ButtonReadCellRenderer,
          }}
          noRowsOverlayComponent='noRowsAvailable'
          noRowsOverlayComponentParams={{
            menu: 'Inbox'
          }}
        />
      </Paper>
      
      <ViewPesan />
    </>
  );
};

export default Pesan;