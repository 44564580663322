import axios from "axios"

export const baseURL = document.querySelector('meta[name="base-url"]').content
export const dataRouteGroup = 'data/'

const http = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: true
})

export const csrfSessionName = '__CSRF'

http.interceptors.request.use(async (config) => {
  if (config.method.toUpperCase() === 'POST') {
    config.data['csrf_key'] = sessionStorage.getItem(csrfSessionName)
  }
  return config
}, async (error) => {
  return Promise.reject(error)
})

http.interceptors.response.use(async (response) => {
  await sessionStorage.setItem(csrfSessionName, response.headers['x-csrf-key'])
  if (response.data['code'] === 403)
  {
    window.location.reload(false)
  }
  return response
}, async (error) => {
  return Promise.reject(error)
})

export default http