import {Box, Button, Grid, Link, Typography} from "@material-ui/core"
import krsStyles from "../../../themes/routes/akademik/krs"
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import {baseURL} from "../../../interfaces/http/http.interface";
import useAuthStore from "../../../stores/auth.store";

const Transkrip = () => {
  const classes = krsStyles()
  const user = useAuthStore( state => state.user )
  
  return (
    <>
      <Box className={classes.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Transkrip Nilai</Typography>
        <Typography variant="body2" gutterBottom align="center">Download / cetak Transkrip Nilai.</Typography>
      </Box>
      
      <Grid container justifyContent="center" direction="column" alignContent="center" alignItems="center" spacing={1}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppRoundedIcon />}
            component={Link}
            href={`${baseURL}cetak/transkrip-mahasiswa/${user.data.npm}`}
            target="_blank"
            rel="noopener"
          >Download Transkrip Nilai
          </Button>
        </Grid>
        <Grid item>
          <Typography align="center" variant="subtitle2">Klik tombol diatas untuk download.</Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Transkrip