import {makeStyles} from "@material-ui/core/styles"
import {grey} from '@material-ui/core/colors'

const drawerStyles = drawerWidth => makeStyles((theme) => ({
  drawerRoot: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: grey[50],
    // borderRight: 0
  },
  drawerHeader: {
    // height: theme.spacing(8),
    padding: theme.spacing(0,1.5),
    backgroundColor: 'white',
    // borderRight: `1px solid ${theme.palette.divider}`
  },
  uirLogo: {
    maxWidth: theme.spacing(4),
    marginRight: theme.spacing(1.5),
    fontFamily: 'Georgia Bold'
  },
  drawerTitle: {
    fontFamily: 'Georgia Regular',
  },
  drawerSubtitle: {
    fontFamily: 'Georgia Bold',
    marginTop: theme.spacing(-.4),
  },
  drawerMenuItem: {
    paddingLeft: theme.spacing(4)
  }
}))

export default drawerStyles