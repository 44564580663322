import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Hidden,
  Typography,
  useMediaQuery
} from "@material-ui/core"
import loginStyles from "../../../themes/routes/login/login"
import uirLogo from '../../../resources/images/uir-new-small.png'
import useAuthStore from "../../../stores/auth.store"
import {useEffect, useState} from "react"
import {Skeleton} from '@material-ui/lab'
import {useForm,} from "react-hook-form"
import AuthService from "../../../services/security/auth-security.service"
import DynamicForms from "../../forms/dynamic.forms"
import {yupResolver} from '@hookform/resolvers/yup'
import generateYupSchema from "../../../helpers/generate-yup_schema"
import * as yup from "yup"
import shallow from "zustand/shallow";
import {useSnackbar} from "notistack";
import Informasi from "../../info/informasi";
import {useTheme} from "@material-ui/core/styles";

const Login = () => {
  const classes = loginStyles()
  const { loginForm, setUser } = useAuthStore(state => ({ setUser: state.setUser, loginForm: state.loginForm }), shallow)
  
  const [schema, setSchema] = useState({})
  useEffect(() => {
    if (loginForm.form.length > 0) {
      setSchema(loginForm.form.reduce(generateYupSchema, {}))
    }
  }, [loginForm.form])
  
  const {
    handleSubmit,
    control,
    formState: {errors, isSubmitting}
  } = useForm({
    shouldFocusError: true,
    resolver: yupResolver(yup.object().shape(schema)),
  });
  
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async post => {
    try {
      const response = await AuthService.verifyUserAccount(post)
      const data = await response.data
      
      if (response.data['error'])
      {
        enqueueSnackbar(response.data['errMsg'], {
          variant: "error"
        });
      }
      else
      {
        setUser(data)
      }
      
    } catch (e) {
      // console.log(e)
    }
  }
  
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  
  return (
    <div className={classes.bodyRoot}>
      <Container fixed disableGutters={xsDown}>
        <Grid container justifyContent="center" spacing={xsDown ? 0:3} className={classes.mainGrid}>
          <Grid item xs={12} md={6} lg={4}> {/*Grid utaman*/}
            <Card variant="outlined" className={classes.cardRoot}>
              <CardHeader
                avatar={
                  <img src={uirLogo} alt="logo uir" className={classes.uirLogo}/>
                }
                title={
                  <Typography variant="body1" className={classes.logoTitle}>UNIVERSITAS ISLAM RIAU</Typography>
                }
                subheader={
                  <Typography variant="subtitle1" className={classes.logoSubtitle}>Program Pascasarjana</Typography>
                }
                classes={{
                  root: classes.cardHeader,
                  avatar: classes.cardHeaderAvatar
                }}
              />
            </Card>
            <div className={classes.headerDivider}/>
            <Hidden mdUp>
              <Informasi />
            </Hidden>
            <div className={classes.formDivider}/>
            <Card variant="outlined" className={classes.cardRoot}>
              <CardHeader
                title={
                  <Typography variant="h6" align="center">Sistem Informasi Akademik</Typography>
                }
                subheader={
                  <Typography variant="subtitle1" align="center">Login untuk melanjutkan</Typography>
                }
              />
              <CardContent className={classes.cardContent}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={3}>
                    {
                      loginForm.form ?
                        loginForm.form.map((element) => (
                          element.type !== 'hidden' &&
                          <Grid item xs={12} key={element.__uid}>
                            <DynamicForms elements={element} control={control} classes={classes.btnLogin} errors={errors} disabled={isSubmitting} fullwidth />
                          </Grid>
                        ))
                        :
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Skeleton variant="rect" height={48} animation="wave" />
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton variant="rect" height={48} animation="wave" />
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton variant="rect" height={48} animation="wave" />
                          </Grid>
                        </Grid>
                    }
                  </Grid>
                </form>
              </CardContent>
              <CardActions style={{justifyContent: 'center'}}>
                <Typography align="center" variant="caption">© 2021 Universitas Islam Riau - All Rights Reserved</Typography>
              </CardActions>
            </Card>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={6} lg={6}> {/*Grid utaman*/}
              <Informasi />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  )
}

export default Login