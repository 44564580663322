import http, {dataRouteGroup} from "../../interfaces/http/http.interface";

const routeGroup = 'user/';

const UserService = {
  fetchUserProfile(param) {
    return http.get(dataRouteGroup + 'profile-mahasiswa');
  },
  loadPasswordForm() {
    return http.get(routeGroup + 'change-password');
  },
  loadDatadiriForm() {
    return http.get(routeGroup + 'edit-profile');
  },
  updatePassword(post) {
    return http.patch(routeGroup + 'change-password', post);
  },
  updateDataDiri(post) {
    return http.patch(routeGroup + 'edit-profile', post);
  },
  loadKontak() {
    return http.get(dataRouteGroup + 'kontak')
  }
};

export default UserService;