import create from 'zustand'

const navigationStore = create((set) => ({
  drawerWidth: 300,
  drawerMenu: [
    {
      header: 'Halaman Depan',
      visible: false,
      menus: [
        {
          label: 'Dashboard',
          url: '/',
          visible: false
        },
      ]
    },
    {
      header: 'Halaman Akademik',
      visible: true,
      menus: [
        {
          label: 'Kartu Rencana Studi (KRS)',
          url: '/cetak-krs',
          visible: true
        },
        {
          label: 'Kartu Hasil Studi (KHS)',
          url: '/cetak-khs',
          visible: true
        },
        {
          label: 'Transkrip Nilai',
          url: '/cetak-transkrip-nilai',
          visible: true
        },
      ]
    },
    {
      header: 'Halaman Keuangan',
      visible: true,
      menus: [
        {
          label: 'Data Tagihan',
          url: '/data-tagihan',
          visible: true
        },
        {
          label: 'Riwayat Pembayaran',
          url: '/riwayat-pembayaran',
          visible: true
        },
        {
          label: 'Panduan Pembayaran',
          url: '/panduan-pembayaran',
          visible: true
        },
      ]
    },
    {
      header: 'Halaman Layanan',
      visible: true,
      menus: [
        {
          label: 'Kartu Tanda Mahasiswa (KTM)',
          url: '/ktm-digital',
          visible: true
        },
        {
          label: 'Pusat Bantuan',
          url: '/kontak',
          visible: true
        },
      ]
    }
  ],
}))

export default navigationStore