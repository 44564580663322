import usePesanStore from "../../../stores/pesan.store";
import {useMutation, useQueryClient} from "react-query";
import InboxDataService from "../../../services/data/inbox-data.service";
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from "@material-ui/core";
import shallow from "zustand/shallow";
import {useEffect} from "react";

const ViewPesan = () => {
  /** ZUSTAND PROPS */
  const {
    messageId,
    setMessageId
  } = usePesanStore((state) => ({
    messageId: state.messageId,
    setMessageId: state.setMessageId,
  }), shallow);
  
  /** REACT QUERY */
  const queryClient = useQueryClient();
  const {mutateAsync, data, isLoading} = useMutation(InboxDataService.fetchMessage);
  
  /** PROPS */
  const messageIdIsNotEmpty = messageId !== '';
  const closeDialog = async () => {
    await setMessageId('');
    // console.log(messageId);
    await queryClient.invalidateQueries('inbox');
  }
  
  /** USE EFFECT */
  useEffect(() => {
    (async () => {
      if (messageId !== '') {
        await mutateAsync({ messageId })
      }
    })()
  }, [messageId, mutateAsync])
  
  // return null if messageId is empty
  if (!messageId) return null;
  
  return (
    <Dialog onClose={closeDialog} open={messageIdIsNotEmpty} maxWidth="sm" fullWidth>
      <DialogTitle>{isLoading ? 'Harap Tunggu...' : data ? data?.judul : 'Pesan kosong'}</DialogTitle>
      <DialogContent>
        {
          isLoading ?
            <Grid container spacing={0} alignItems="center">
              <Grid item>
                <CircularProgress/>
              </Grid>
              <Grid item>
                <Typography style={{marginLeft: '16px'}}>Sedang mengambil pesan ...</Typography>
              </Grid>
            </Grid>
            :
            data ?
              <>
                <Chip label={data?.tgl_kirim} size="small" variant="outlined" color="primary"/>
                <DialogContentText style={{marginTop: '8px'}}>
                  {data?.isi}
                </DialogContentText>
              </>
              :
              <Typography variant="body2">Pesan tidak ditemukan</Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ViewPesan;