import {Controller} from "react-hook-form"
import {TextField} from "@material-ui/core"
import NumberFormat from "react-number-format";

const NumberInput = ({ element, control, errors, disabled, variant, format }) => {
  return (
    <Controller
      name={element.name}
      control={control}
      defaultValue={element.defaultValue}
      render={({ field }) => (
        <TextField
            {...field}
            type={element.type}
            label={element.label}
            fullWidth
            variant={variant || "outlined"}
            error={Boolean(errors[element.name])}
            helperText={errors[element.name]?.message}
            disabled={disabled}
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                format: element.format
              }
            }}
          />
      )}
    />
  )
}

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, format, ...rest } = props;
  
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(v) => onChange(v.value)}
      format={format}
    />
  );
};

export default NumberInput;