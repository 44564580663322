import {Box, CircularProgress, Divider, Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import UserService from "../../../services/data/user-data.service";
import commonStyles from "../../../themes/common/common";

const Kontak = () => {
  const commonClasses = commonStyles();
  const [isLoadingKontak, setIsLoadingKontak] = useState(false);
  const [kontak, setKontak] = useState([]);
  
  
  useEffect(() => {
    setIsLoadingKontak(true);
    UserService.loadKontak()
      .then((res) => {
        if (res.status === 200) {
          setKontak(res.data);
          setIsLoadingKontak(false);
        }
      })
      .catch(() => {
        setIsLoadingKontak(false);
      });
  }, [])
  
  return (
    <>
      <Box className={commonClasses.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Pusat Bantuan</Typography>
        <Typography variant="body2" gutterBottom align="center">Jika Anda membutuhkan bantuan, silahkan hubungi kontak
          dibawah.</Typography>
      </Box>
      
      <Grid container justifyContent="center" spacing={6}>
        {
          !isLoadingKontak && kontak.length > 0 ?
            kontak.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item>
                  <Typography align="center" style={{fontWeight: 500}}>{item.nama_kontak}</Typography>
                  <Typography align="center" variant="body2">{item.keterangan}</Typography>
                  <Typography align="center">{item.no_hp}</Typography>
                </Grid>
                {(
                  index + 1) !== kontak.length && (
                  <Grid item>
                    <Divider orientation="vertical"/>
                  </Grid>
                )}
              
              </React.Fragment>
            ))
            : <CircularProgress/>
        }
      </Grid>
    </>
  );
};

export default Kontak