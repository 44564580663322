import {CircularProgress, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import useUserStore from "../../../../stores/user.store";
import shallow from "zustand/shallow";
import {useEffect} from "react";

const ViewProfile = ({ npm }) => {
  const {isLoading, profile, loadProfile} = useUserStore(state => ({
    isLoading: state.isLoading,
    profile: state.profile,
    loadProfile: state.loadProfile
  }), shallow);
  
  useEffect(() => {
      loadProfile(npm)
  }, [loadProfile, npm]);
  
  return (
    <List disablePadding>
      {
        !isLoading && profile ?
          Object.keys(profile).map(
            (key, index) =>
              <ListItem disableGutters dense divider key={index}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="textSecondary">{key}</Typography>
                  }
                  secondary={
                    <Typography variant="body1">{profile[key] ? profile[key]:'-'}</Typography>
                  }
                />
              </ListItem>
          ) : <CircularProgress />
      }
    </List>
  );
};

export default ViewProfile;