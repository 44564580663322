import DefaultInput from "./elements/default-input";
import {Button,} from "@material-ui/core";
import NumberInput from "./elements/number-input";

const DynamicForms = ({elements, control, errors, classes, disabled, variant, fullwidth}) => {
  const {type} = elements;
  
  switch (type) {
    case "hidden":
      return <input type={elements.type} name={elements.name} value={elements.defaultValue} ref={control}/>
    case "submit":
      return <Button variant="contained" fullWidth={fullwidth} color="primary" size="large"
                     className={classes} disableElevation type="submit"
                     disabled={disabled}>{elements.label}</Button>
    case "numeric":
      return <NumberInput element={elements} control={control} errors={errors} disabled={disabled} variant={variant} />
    default:
      return <DefaultInput element={elements} control={control} errors={errors} disabled={disabled} variant={variant}/>
  }
};

DynamicForms.defaultProps = {
  fullWidth: true,
  classes: ''
}

export default DynamicForms;