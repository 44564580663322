import UserService from "../../../../services/data/user-data.service";
import FormProfile from "./form.profile";
import {useEffect, useState} from "react";

const UpdatePassword = ({ handleClickOpen }) => {
  const [passwordForm, setPasswordForm] = useState([]);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  
  const openDialog = (data, statusCode) => {
    handleClickOpen(data, statusCode);
  };
  
  useEffect(() => {
    setIsLoadingForm(true);
    UserService.loadPasswordForm()
      .then((res) => {
        if (res.status === 200) {
          setIsLoadingForm(false);
          setPasswordForm(res.data);
        }
      });
  }, []);
  
  const submitForm = async post => {
    await UserService.updatePassword(post)
      .then((res) => {
        if (res.status === 200) {
          openDialog(res.data, res.status)
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            openDialog(err.response.data, err.response.status);
          }
        }
      });
  }
  
  return (
    <FormProfile formElements={passwordForm} onSubmit={submitForm} isLoadingForm={isLoadingForm} />
  )
};

export default UpdatePassword;