import FormProfile from "./form.profile";
import UserService from "../../../../services/data/user-data.service";
import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";

const EditProfile = () => {
  const [dataDiriForm, setDataDiriForm] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  
  useEffect(() => {
    setIsLoadingForm(true);
    UserService.loadDatadiriForm()
      .then((res) => {
        if (res.status === 200) {
          setIsLoadingForm(false);
          setDataDiriForm(res.data);
        }
      })
  }, [])
  
  const submitForm = async post => {
    await UserService.updateDataDiri(post)
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(res.data['dialogMsg'], {
            variant: "success"
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          enqueueSnackbar(err.response.data['dialogMsg'], {
            variant: "error"
          })
        }
      })
  }
  
  return (
    <FormProfile formElements={dataDiriForm} onSubmit={submitForm} isLoadingForm={isLoadingForm} />
  )
};

export default EditProfile;