import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from "@material-ui/core"
import useAuthStore from "../../../stores/auth.store"
import profileStyles from "../../../themes/routes/profile/profile"
import React, {useState} from "react";
import Image from "material-ui-image";
import ViewProfile from "./partials/view-profile";
import EditProfile from "./partials/edit-profile";
import UpdatePassword from "./partials/update-password";
import dompurify from "dompurify";
import TabPanel, {a11yProps} from "../../common/tab-properties";

const Profile = () => {
  const classes = profileStyles()
  const {data} = useAuthStore(state => state.user)
  
  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => setValue(newValue);
  
  const [dialogData, setDialogData] = React.useState({
    open: false,
    status: 0,
    dialogTitle: '',
    dialogMsg: '',
    btnTitle: '',
    handleDialogClose: undefined
  });
  
  const handleClickOpen = ( data, code ) => {
    setDialogData({
      open: true,
      status: code,
      dialogTitle: data.dialogTitle,
      dialogMsg: data.dialogMsg,
      btnTitle: data.btnTitle,
    });
  };
  
  const logout = useAuthStore(state => state.logoutUser)
  
  const handleClose = () => {
    if (dialogData.status === 200) {
      logout();
    }
    
    if (dialogData.status === 401) {
      setDialogData({
        ...dialogData,
        open: false
      });
    }
  };
  
  const sanitizer = dompurify.sanitize;
  
  return (
    <>
      <Box className={classes.pageTitle}>
        <Typography variant="h5" gutterBottom align="center">Profile</Typography>
        <Typography variant="body2" gutterBottom align="center">Data diri mahasiswa.</Typography>
      </Box>
  
  
      <Tabs
        value={value}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
      >
        <Tab label="Profile Mahasiswa" {...a11yProps(0)} />
        <Tab label="Ubah Data" {...a11yProps(1)} />
        <Tab label="Ubah Password" {...a11yProps(2)} />
      </Tabs>
      <Divider className={classes.tabsDivider}/>
      <Grid container justifyContent="center" spacing={6}>
        <Grid item>
          <Avatar className={classes.profileImage} variant="rounded">
            <Image
              aspectRatio={(11 / 16)}
              src={`https://pmb.pps.uir.ac.id/foto${data.jenjang}_sikad/${data.file_foto}`}
            />
          </Avatar>
        </Grid>
        <Grid item xs={12} sm>
          <Toolbar disableGutters>
            <Box
              marginRight={6}
            >
              <Typography variant="h6">{data.nama}</Typography>
              <Typography variant="body2">{data.npm}</Typography>
            </Box>
          </Toolbar>
          <Divider className={classes.dataDivider}/>
          <TabPanel value={value} index={0}>
            <ViewProfile npm={data.npm}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EditProfile/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UpdatePassword handleClickOpen={handleClickOpen}/>
          </TabPanel>
        </Grid>
      </Grid>
      <Dialog
        open={dialogData.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{sanitizer(dialogData.dialogTitle)}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" dangerouslySetInnerHTML={{__html: sanitizer(dialogData.dialogMsg)}}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {dialogData.btnTitle ?? 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Profile