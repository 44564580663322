import create from 'zustand'
import AuthService from "../services/security/auth-security.service";

const handlePreAuth = async (set, get) => {
  try {
    const response = await AuthService.fetchLoginForm()
    const data = await response.data
    
    if (data.row > 0) {
      set({ user: data })
    }
    if (data.form) {
      set({ loginForm: data })
    }
  } catch (e) {
    console.log(e)
  }
}

const handleLogout = async (set, get) => {
  try {
    await AuthService.logoutUserAccount()
    set({
      user: authStore().user,
      loginForm: []
    })
  } catch (e) {
    console.log(e)
  }
}

const authStore = (set,get) => ({
  loginForm: [],
  user: {
    row: 0,
    data: {
      npm: undefined,
      passwd: undefined,
      nama: undefined,
      no_handphone1: undefined,
      file_foto: undefined,
      jenjang: undefined
    },
    errMsg: ''
  },
  setUser: user => set({ user: user }),
  setLoginForm: loginForm => set({loginForm: loginForm}),
  preAuth: async () => { await handlePreAuth(set, get) },
  logoutUser: async () => { await handleLogout(set, get) }
})

const useAuthStore = create(authStore)

export default useAuthStore