import Appbar from "../../common/appbar-common";
import DrawerApp from "../../common/drawer-common";
import dashboardTemplateStyles from "../../../themes/routes/root/dashboard-template";
import navigationStore from "../../../stores/navigation.store";
import clsx from "clsx";
import {Box, Container, Divider, Toolbar, Typography, useMediaQuery} from "@material-ui/core";
import {Route, Switch, useLocation} from "react-router-dom";
import Krs from "../akademik/krs";
import Khs from "../akademik/khs";
import Transkrip from "../akademik/transkrip";
import Tagihan from "../keuangan/tagihan";
import Riwayat from "../keuangan/riwayat";
import {useEffect, useMemo, useState} from "react";
import Profile from "../profile/profile";
import Panduan from "../keuangan/panduan";
import Kontak from "../kontak/kontak";
import {useTheme} from "@material-ui/core/styles";
import Pesan from "../pesan/pesan";
import Info from "./info";
import TagihanNotification from "../keuangan/tagihan-notification";
import Ktm from "../ktm/ktm";

const DashboardTemplate = () => {
  const drawerWidth = navigationStore(state => state.drawerWidth)
  
  const classes = dashboardTemplateStyles(drawerWidth)()
  const [drawerOpen, setDrawerOpen] = useState(localStorage.getItem("drw") === 'true' || false);
  
  const {pathname} = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  
  const handleSetDrawer = () => setDrawerOpen(!drawerOpen);
  
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  
  useEffect(() => {
    if (!mdUp) return setDrawerOpen(false);
    if (mdUp) return setDrawerOpen(true);
  }, [mdUp])
  
  const panduanPembayaran = useMemo(() => <Panduan/>, []);
  
  return (
    <>
      <DrawerApp drawerOpen={drawerOpen} setDrawerOpen={handleSetDrawer} mdUp={mdUp}/>
      <Appbar drawerOpen={drawerOpen} setDrawerOpen={handleSetDrawer}/>
      <Toolbar/>
      <main className={clsx(classes.contentRoot, {
        [classes.contentShift]: drawerOpen
      })}>
        <Container maxWidth="md" fixed className={classes.mainContentContainer}>
          <Switch>
            <Route path="/" exact><Info/></Route>
            <Route path="/cetak-krs" exact><Krs/></Route>
            <Route path="/cetak-khs" exact><Khs/></Route>
            <Route path="/cetak-transkrip-nilai" exact><Transkrip/></Route>
            <Route path="/data-tagihan" exact><Tagihan/></Route>
            <Route path="/riwayat-pembayaran" exact><Riwayat/></Route>
            <Route path="/panduan-pembayaran" exact>{panduanPembayaran}</Route>
            <Route path="/profile" exact><Profile/></Route>
            <Route path="/kontak" exact><Kontak /></Route>
            <Route path="/ktm-digital" exact><Ktm /></Route>
            <Route path="/pesan"><Pesan/></Route>
            <Route path="/info-dump"><Info /></Route>
          </Switch>
        </Container>
      </main>
      <Box
        paddingTop={1}
        paddingBottom={2}
        className={clsx(classes.footerRoot, {
          [classes.footerShift]: drawerOpen
        })}
      >
        <Divider variant="middle" className={classes.footerDivider}/>
        <Toolbar>
          <Typography variant="caption" align="center" color="textSecondary">Sistem Informasi Akademik (SIKAD) adalah
            layanan sistem informasi berbasis online dengan fasilitas untuk mencetak Kartu Hasil Studi (KHS), mencetak
            Transkrip Nilai, mengetahui transaksi pembayaran maupun tagihan keuangan, dan update informasi terbaru
            seputar Kampus bagi mahasiswa Universitas Islam Riau. SIKAD ini diharapkan dapat memberi kemudahan bagi
            setiap civitas akademika untuk melakukan aktivitas-aktivitas akademik</Typography>
        </Toolbar>
      </Box>
      <TagihanNotification />
    </>
  )
}

export default DashboardTemplate