import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Typography
} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useQuery} from "react-query";
import InfoDataService from "../../services/data/info-data.service";

import {format} from "date-fns";
import id from "date-fns/locale/id";
// import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";
// import Swiper core and required modules
import SwiperCore, {Autoplay, Scrollbar} from 'swiper/core';
import useInfoStore from "../../stores/info-store";
import InfoDetail from "../routes/root/info-detail";
// install Swiper modules
SwiperCore.use([Autoplay, Scrollbar]);

const useStyle = makeStyles((theme) => ({
  cardWrapper: {
    borderRadius: theme.spacing(1),
    // borderColor: '#030',
    backgroundColor: 'rgba(255, 255, 255, .8)',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      border: 0,
    },
    backdropFilter: "blur(3px)"
  },
  cardWrapperHeader: {
    paddingTop: `12px !important`,
    paddingBottom: `12px !important`,
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1)
    }
  },
  cardWrapperHeaderAvatar: {
    marginRight: 0
  },
  cardWrapperContent: {
    padding: `${theme.spacing(0, 1, 1)} !important`
  },
  cardWrapperIcon: {
    backgroundColor: "transparent",
  },
  cardRoot: {
    borderRadius: theme.spacing(1),
  },
  cardActionArea: {
    position: "relative"
  },
  cardInfoTitle: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage: `linear-gradient(transparent,rgba(0,0,0,0.8))`,
  },
  cardMedia: {
    height: 0,
    paddingTop: '60%',
  }
}))

const Informasi = () => {
  const classes = useStyle();
  
  /** REACT QUERY PROPERTY */
  const {data, isLoading} = useQuery('info', InfoDataService.fetchInfo);
  
  const setInfoId = useInfoStore(state => state.setInfoId);
  
  if (!data && isLoading) {
    return <CircularProgress/>
  }
  
  return (
    <>
      <Card className={classes.cardWrapper} variant="outlined">
        <CardHeader
          classes={{
            avatar: classes.cardWrapperHeaderAvatar
          }}
          className={classes.cardWrapperHeader}
          avatar={
            <Avatar variant="rounded" className={classes.cardWrapperIcon}>
              <InfoOutlinedIcon fontSize="medium" color="action"/>
            </Avatar>
          }
          title={<Typography variant="subtitle2">Info Terbaru</Typography>}
        />
        <CardContent className={classes.cardWrapperContent}>
          <Swiper
            scrollbar={{
              "hide": true
            }}
            className="mySwiper"
            autoplay={{
              "delay": 2500,
              "disableOnInteraction": false
            }}
            spaceBetween={18}
          >
            {
              data.map((item, index) => {
                const formattedTanggalInfo = format(new Date(item.tgl_info), 'EEEE, dd MMM yyyy', {
                  locale: id
                });
                return (
                  <SwiperSlide key={index}>
                    <Card variant="outlined" className={classes.cardRoot}>
                      <CardActionArea className={classes.cardActionArea} onClick={() => setInfoId(item.id)}>
                        <CardMedia
                          className={classes.cardMedia}
                          image={item.headline}
                          title={item.judul}
                        />
                        <CardContent className={classes.cardInfoTitle}>
                          <Typography variant="h6" component="h2" style={{color: '#fff'}}>
                            {item.judul}
                          </Typography>
                          <Typography variant="caption" style={{color: '#9b9b9b'}} component="p">
                            {formattedTanggalInfo}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </CardContent>
      </Card>
      <InfoDetail/>
    </>
  );
};

export default Informasi;